<template>
  <div class="full-view">
    <div @click="$emit('close')" class="bg"></div>
    <button @click="$emit('close')" type="button" class="btn-close">
      <img src="@/assets/icon/close.svg" />
    </button>
    <Swiper
      :slides-per-view="1"
      :navigation="{
        nextEl: '.button-next-full-view',
        prevEl: '.button-prev-full-view',
      }"
      :modules="modules"
      :initialSlide="initialSlide"
      loop
      @swiper="onSwiperView"
      class="swiper-full-view"
    >
      <SwiperSlide v-for="(item, i) in array" :key="i">
        <img :src="require(`@/assets/img/${room}/img-${i + 1}.png`)" />
      </SwiperSlide>
      <div class="buttons">
        <ButtonSwiper class="button-prev-full-view" :btn-width="32" prev />
        <ButtonSwiper class="button-next-full-view" :btn-width="32" />
      </div>
    </Swiper>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import { Navigation } from 'swiper'
import ButtonSwiper from './ButtonSwiper.vue'

export default {
  props: {
    initialSlide: {
      type: Number,
      default: 0,
    },
    room: {
      type: String,
      default: 'room-8',
    },
    count: {
      type: Number,
      default: 13,
    },
  },
  name: 'FullView',
  components: {
    Swiper,
    SwiperSlide,
    ButtonSwiper,
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', this.close)
  },
  unmounted() {
    document.body.style.overflow = 'auto'
    document.removeEventListener('keydown', this.close)
  },
  computed: {
    array() {
      return new Array(this.count)
    },
  },
  methods: {
    close(e) {
      if (e.code == 'Escape') this.$emit('close')
    },
  },
  setup() {
    const swiperView = ref(null)

    const onSwiperView = (sw) => {
      swiperView.value = sw
    }

    return {
      onSwiperView,
      modules: [Navigation],
    }
  },
}
</script>

<style lang="scss" scoped>
.full-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  align-items: center;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.6);
  }
  .swiper-full-view {
    width: 100%;
    @include desktopXL {
      padding-bottom: uniWXL(50);
    }
    @include desktop {
      padding-bottom: uniW(50);
    }
    @include mobile {
      padding-bottom: uniWM(50);
    }
    img {
      max-width: 100vw;
      width: auto;
      margin: 0 auto;
      max-height: 80vh;
      @include mobile {
        width: 100%;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    max-width: 80vw;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn-close {
    position: absolute;
    filter: invert(1);
    @include desktopXL {
      top: uniWXL(20);
      right: uniWXL(20);
      width: uniWXL(24);
    }
    @include desktop {
      top: uniW(20);
      right: uniW(20);
      width: uniW(24);
    }
    @include mobile {
      top: uniWM(20);
      right: uniWM(20);
      width: uniWM(24);
    }
  }
}
</style>
