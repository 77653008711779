<template>
  <div class="switch">
    <button
      :class="{ active: summer }"
      @click="changeSeason(true)"
      type="button"
    >
      <img src="@/assets/icon/leaf.svg" />лето
    </button>
    <button
      :class="{ active: !summer }"
      @click="changeSeason(false)"
      type="button"
    >
      <img src="@/assets/icon/flake.svg" />зима
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'ButtonSwitch',
  computed: {
    ...mapState(['summer']),
  },
  methods: {
    ...mapMutations(['changeSeason']),
  },
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  @include desktopXL {
    border-radius: uniWXL(30);
    padding: uniWXL(3);
  }
  @include desktop {
    border-radius: uniW(30);
    padding: uniW(3);
  }
  @include mobile {
    border-radius: uniWM(30);
    padding: uniWM(3);
    position: absolute;
    bottom: uniWM(47);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  button {
    color: $main-grey;
    text-transform: uppercase;
    font-family: var(--montserratFont);
    @include desktopXL {
      font-size: uniWXL(14);
      line-height: uniWXL(17);
      border-radius: uniWXL(30);
      padding: uniWXL(4) uniWXL(18);
    }
    @include desktop {
      font-size: uniW(14);
      line-height: uniW(17);
      border-radius: uniW(30);
      padding: uniW(4) uniW(18);
    }
    @include mobile {
      font-size: uniWM(14);
      line-height: uniWM(17);
      border-radius: uniWM(30);
      padding: uniWM(4) uniWM(18);
    }
    img {
      @include desktopXL {
        width: uniWXL(12);
        margin-right: uniWXL(6);
      }
      @include desktop {
        width: uniW(12);
        margin-right: uniW(6);
      }
      @include mobile {
        width: uniWM(12);
        margin-right: uniWM(6);
      }
    }
    &.active {
      background: $main-grey;
      color: $white;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
</style>
