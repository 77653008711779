<template>
  <section class="map" id="map">
    <div class="ya-map">
      <iframe
        v-if="device !== 'mobile'"
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab8fbdb7793900a735924ee74522d4791e74ac1fe2fdbdcbc4229d6362f4e9fd0&amp;source=constructor"
        frameborder="0"
      ></iframe>
      <iframe
        v-else
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac05a533b125fa524455d80621621231d4c691cf14ae17d502a276a00d3b0370a&amp;source=constructor"
        frameborder="0"
      ></iframe>
    </div>
    <div class="address">
      <p class="address-orange">
        <span>Адрес:</span><br />
        Сочи, Красная Поляна поселок, город Сочи,<br
          v-if="device !== 'mobile'"
        />
        ул. Вознесенская 24/1<br /><br />
        <span>Координаты:</span><br v-if="device == 'mobile'" />
        43.676854, 40.203948
      </p>
      <ul v-if="device !== 'mobile'" class="address-list">
        <li>
          Апартаменты расположены в самом центре поселка Красная Поляна в 10
          минутах езды от всех горнолыжных курортов.
        </li>
        <li>
          Рядом с апартаментами находится несколько ресторанов и кафе, столовая,
          Сбербанк, продуктовый магазин, а также продуктовый рынок.
        </li>
        <li>
          В 2 минутах находится автобусная остановка «Вертодром», куда приходят
          автобусы из аэропорта и следуют автобусы на курорты.
        </li>
      </ul>
    </div>
    <ul v-if="device == 'mobile'" class="address-list">
      <li>
        Апартаменты расположены в самом центре поселка Красная Поляна в 10
        минутах езды от всех горнолыжных курортов.
      </li>
      <li>
        Рядом с апартаментами находится несколько ресторанов и кафе, столовая,
        Сбербанк, продуктовый магазин, а также продуктовый рынок.
      </li>
      <li>
        В 2 минутах находится автобусная остановка «Вертодром», куда приходят
        автобусы из аэропорта и следуют автобусы на курорты.
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'SectionMap',
}
</script>

<style lang="scss" scoped>
.map {
  position: relative;
  @include desktopXL {
    margin-bottom: uniWXL(93);
    height: uniWXL(678);
  }
  @include desktop {
    margin-bottom: uniW(93);
    height: uniW(678);
  }
  @include mobile {
    margin-bottom: uniWM(0);
    height: auto;
  }
  .ya-map {
    width: 100%;
    iframe {
      width: 100%;
      @include desktopXL {
        height: uniWXL(678);
      }
      @include desktop {
        height: uniW(678);
      }
      @include mobile {
        height: uniWM(614);
      }
    }
    @include desktopXL {
      height: uniWXL(678);
    }
    @include desktop {
      height: uniW(678);
    }
    @include mobile {
      height: uniWM(614);
    }
  }
}
.address {
  position: absolute;
  width: fit-content;
  top: 0;
  z-index: 1;
  background: rgb(43, 43, 43, 0.8);
  @include desktopXL {
    padding-top: uniWXL(124);
    padding-left: uniWXL(320);
    padding-right: uniWXL(88);
    height: uniWXL(678);
  }
  @include desktop {
    padding-top: uniW(124);
    padding-left: uniW(80);
    padding-right: uniW(88);
    height: uniW(678);
  }
  @include mobile {
    background: rgb(43, 43, 43, 0.8);
  }
  &-orange {
    color: $orange;
    width: fit-content;
    @include desktopXL {
      font-size: uniWXL(16);
      line-height: uniWXL(22);
      margin-bottom: uniWXL(40);
    }
    @include desktop {
      font-size: uniW(16);
      line-height: uniW(22);
      margin-bottom: uniW(40);
    }
    @include mobile {
      font-size: uniWM(16);
      line-height: uniWM(22);
      padding: uniWM(20) uniWM(22);
      text-align: center;
      color: $white;
    }
    span {
      color: $orange;
    }
  }
  &-list {
    @include reset-list;
    width: fit-content;
    @include mobile {
      background: $main-grey;
      padding: uniWM(42) uniWM(24) uniWM(39) uniWM(24);
    }
    li {
      color: $white;
      position: relative;
      &:nth-child(1) {
        &::before {
          background: url('../assets/icon/setting-list-1.svg') no-repeat;
          background-size: cover;
        }
      }
      &:nth-child(2) {
        &::before {
          background: url('../assets/icon/setting-list-2.svg') no-repeat;
          background-size: cover;
        }
      }
      &:nth-child(3) {
        &::before {
          background: url('../assets/icon/setting-list-3.svg') no-repeat;
          background-size: cover;
        }
      }
      &::before {
        position: absolute;
        content: '';
        left: 0;
        @include desktopXL {
          width: uniWXL(30);
          height: uniWXL(30);
        }
        @include desktop {
          width: uniW(30);
          height: uniW(30);
        }
        @include mobile {
          width: uniWM(30);
          height: uniWM(30);
        }
      }
      @include desktopXL {
        font-size: uniWXL(16);
        line-height: uniWXL(21);
        margin-bottom: uniWXL(20);
        max-width: uniWXL(413);
        padding-left: uniWXL(40);
      }
      @include desktop {
        font-size: uniW(16);
        line-height: uniW(21);
        margin-bottom: uniW(20);
        max-width: uniW(413);
        padding-left: uniW(40);
      }
      @include mobile {
        font-size: uniWM(16);
        line-height: uniWM(21);
        margin-bottom: uniWM(20);
        max-width: uniWM(413);
        padding-left: uniWM(40);
      }
    }
  }
}
</style>
