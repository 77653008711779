<template>
  <section class="contacts" id="contacts">
    <div class="container">
      <div class="contacts-wrapper">
        <img
          v-if="device !== 'mobile'"
          src="@/assets/img/contacts.png"
          class="contacts-bg"
        />
        <img v-else src="@/assets/img/contacts-mobile.png" />
        <div class="contacts-text-block">
          <img src="@/assets/icon/logo-white.svg" class="contacts-logo" />
          <div class="contacts-titles">
            <h1 class="contacts-title">Апартаменты</h1>
            <h1 class="contacts-subtitle">#sVidomNaGory</h1>
          </div>
          <p class="phone">+7 (903) 596-09-33</p>
          <a href="mailto:svidomnagory.project@gmail.com" class="mail">
            svidomnagory.project@gmail.com
          </a>
          <a href="https://t.me/svidomnagory" target="_blank" class="tg">
            svidomnagory
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionContacts',
}
</script>

<style lang="scss" scoped>
.contacts {
  @include desktopXL {
    padding-bottom: uniWXL(92);
  }
  @include desktop {
    padding-bottom: uniW(92);
  }
  @include mobile {
    padding-bottom: uniWM(21);
  }
  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @include mobile {
      flex-direction: column;
      margin: 0 uniWM(-20);
    }
  }
  &-bg {
    @include desktopXL {
      width: uniWXL(738);
    }
    @include desktop {
      width: uniW(738);
    }
    @include mobile {
      width: 100%;
      margin: 0 uniWM(-20);
    }
  }
  &-text-block {
    background: $main-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include desktopXL {
      width: uniWXL(630);
      height: uniWXL(404);
      margin-right: uniWXL(-88);
    }
    @include desktop {
      width: uniW(630);
      height: uniW(404);
      margin-right: uniW(-88);
    }
    @include mobile {
      width: calc(100% - uniWM(40));
      height: auto;
      padding: uniWM(40) uniWM(20);
      margin-top: uniWM(-139);
    }
    p {
      color: $white;
      text-align: center;
      @include desktopXL {
        line-height: uniWXL(22);
      }
      @include desktop {
        line-height: uniW(22);
      }
      @include mobile {
        line-height: uniWM(16);
      }
      &.phone {
        @include desktopXL {
          margin-bottom: uniWXL(5);
        }
        @include desktop {
          margin-bottom: uniW(5);
        }
        @include mobile {
          margin-bottom: uniWM(5);
        }
      }
    }
    a {
      color: $white;
      text-decoration: underline;
      @include desktopXL {
        line-height: uniWXL(21);
      }
      @include desktop {
        line-height: uniW(21);
      }
      @include mobile {
        line-height: uniWM(21);
      }
      &.mail {
        @include desktopXL {
          margin-bottom: uniWXL(13);
        }
        @include desktop {
          margin-bottom: uniW(13);
        }
        @include mobile {
          margin-bottom: uniWM(13);
        }
      }
      &.tg {
        position: relative;
        @include desktopXL {
          padding-left: uniWXL(27);
        }
        @include desktop {
          padding-left: uniW(27);
        }
        @include mobile {
          padding-left: uniWM(27);
        }
        &::after {
          position: absolute;
          content: '';
          background: url('../assets/icon/tg.svg') no-repeat;
          background-size: cover;
          top: 50%;
          transform: translateY(-50%);
          @include desktopXL {
            width: uniWXL(17);
            height: uniWXL(17);
            left: uniWXL(-1);
          }
          @include desktop {
            width: uniW(17);
            height: uniW(17);
            left: uniW(-1);
          }
          @include mobile {
            width: uniWM(17);
            height: uniWM(17);
            left: uniWM(-1);
          }
        }
      }
    }
  }
  &-titles {
    @include desktopXL {
      margin-bottom: uniWXL(23);
    }
    @include desktop {
      margin-bottom: uniW(23);
    }
    @include mobile {
      margin-bottom: uniWM(23);
    }
    .contacts-title {
      color: $white;
      @include desktopXL {
        line-height: uniWXL(64);
        font-size: uniWXL(48);
      }
      @include desktop {
        line-height: uniW(64);
        font-size: uniW(48);
        margin-botto: uniW(23);
      }
      @include mobile {
        line-height: uniWM(30);
        font-size: uniWM(30);
      }
    }
    .contacts-subtitle {
      color: $orange;
      font-style: italic;
      @include desktopXL {
        line-height: uniWXL(65);
        font-size: uniWXL(49);
        margin-left: uniWXL(42);
        margin-top: uniWXL(-15);
      }
      @include desktop {
        line-height: uniW(65);
        font-size: uniW(49);
        margin-left: uniW(42);
        margin-top: uniW(-15);
      }
      @include mobile {
        line-height: uniWM(33);
        font-size: uniWM(33);
        margin-left: uniWM(30);
        margin-top: uniWM(0);
      }
    }
  }
  &-logo {
    @include desktopXL {
      width: uniWXL(59);
      margin-bottom: uniWXL(0);
    }
    @include desktop {
      width: uniW(59);
      margin-bottom: uniW(0);
    }
    @include mobile {
      width: uniWM(59);
      margin-bottom: uniWM(8);
    }
  }
}
</style>
