<template>
  <header class="header" ref="header" :class="{ fixed: isFixed }">
    <div class="container header-wrapper">
      <div v-if="device == 'mobile'" class="header-mock" />
      <nav class="header-nav">
        <img
          v-if="device !== 'mobile'"
          src="@/assets/icon/logo.svg"
          class="header-logo"
        />
        <img v-else src="@/assets/icon/logo-white.svg" class="header-logo" />
        <ul v-if="device !== 'mobile'">
          <li>
            <button
              @click="scrollTo('#main')"
              type="button"
              :class="{ active: activeElement == 'main' }"
            >
              Главная
            </button>
          </li>
          <li>
            <button
              @click="scrollTo('#map', { offset: -headerHeight })"
              :class="{ active: activeElement == 'map' }"
              type="button"
            >
              Расположение
            </button>
          </li>
          <li>
            <button
              @click="scrollTo('#booking', { offset: -headerHeight })"
              :class="{ active: activeElement == 'booking' }"
              type="button"
            >
              Бронь
            </button>
          </li>
          <li>
            <button
              @click="scrollTo('#apartments', { offset: -headerHeight })"
              :class="{ active: activeElement == 'apartments' }"
              type="button"
            >
              Наши апартаменты
            </button>
          </li>
          <li>
            <button
              @click="scrollTo('#contacts', { offset: -headerHeight })"
              :class="{ active: activeElement == 'contacts' }"
              type="button"
            >
              Контакты
            </button>
          </li>
        </ul>
      </nav>
      <NavMobile v-if="device == 'mobile'" @close="changeMenu(false)" />
      <button
        class="header-burger-btn"
        v-if="device === 'mobile'"
        @click="changeMenu(true)"
      >
        <img src="@/assets/icon/burger.svg" />
      </button>
      <ButtonSwitch v-if="device !== 'mobile' && !isFixed" />
      <a
        v-if="device !== 'mobile' && isFixed"
        href="https://reservationsteps.ru/rooms/index/8fd0fe63-c684-4f99-a522-fc6030425767"
        target="_blank"
        class="btn btn-orange main-button"
      >
        забронировать номер
      </a>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ButtonSwitch from './ButtonSwitch.vue'
import { scroller } from 'vue-scrollto/src/scrollTo'
import NavMobile from './NavMobile.vue'
export default {
  name: 'Header',
  components: { ButtonSwitch, NavMobile },
  data() {
    return {
      scrollTo: scroller(),
      isFixed: false,
      activeElement: 'main',
    }
  },
  mounted() {
    if (this.device !== 'mobile') {
      this.init()
    }
  },
  watch: {
    device(val) {
      if (val == 'mobile') this.isFixed = false
      else this.init()
    },
  },
  computed: {
    ...mapState(['headerHeight']),
  },
  methods: {
    ...mapMutations(['setHeaderHeight', 'changeMenu']),
    init() {
      window.addEventListener('resize', this.mathHeaderHeight)
      document.addEventListener('scroll', this.scrollPage)
    },
    scrollPage() {
      const mainHeight = document.getElementById('main').offsetHeight
      const map = document.getElementById('map')
      const booking = document.getElementById('booking')
      const apartments = document.getElementById('apartments')
      const scrollTop = document.documentElement.scrollTop
      const scrollEnd =
        scrollTop + document.documentElement.clientHeight >=
        document.documentElement.scrollHeight
      const diff = mainHeight - scrollTop
      if (diff <= this.headerHeight && this.device !== 'mobile')
        this.isFixed = true
      else this.isFixed = false

      const diffMap = map.offsetTop - scrollTop - this.headerHeight
      const diffBooking = booking.offsetTop - scrollTop - this.headerHeight
      const diffApartments =
        apartments.offsetTop - scrollTop - this.headerHeight
      if (diffMap <= 0 && -diffMap <= map.offsetHeight)
        this.activeElement = 'map'
      else if (diffBooking <= 0 && -diffBooking <= booking.offsetHeight)
        this.activeElement = 'booking'
      else if (
        diffApartments <= 0 &&
        -diffApartments <= apartments.offsetHeight &&
        !scrollEnd
      )
        this.activeElement = 'apartments'
      else if (scrollEnd) this.activeElement = 'contacts'
      else if (!this.isFixed) this.activeElement = 'main'
    },
    mathHeaderHeight() {
      const { header } = this.$refs
      this.setHeaderHeight(header.offsetHeight)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  @include desktopXL {
    height: uniWXL(104);
  }
  @include desktop {
    height: uniW(104);
  }
  @include mobile {
    height: uniWM(78);
  }
  &.fixed {
    position: fixed;
    right: 0;
    background: $white;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @include mobile {
      align-items: flex-start;
      padding-top: uniWM(30);
    }
  }
  &-logo {
    @include desktopXL {
      margin-right: uniWXL(41);
      width: uniWXL(69);
    }
    @include desktop {
      margin-right: uniW(41);
      width: uniW(69);
    }
    @include mobile {
      margin-right: 0;
      width: uniWM(59);
      filter: brightness(0) invert(1);
    }
  }
  &-mock {
    @include mobile {
      width: uniWM(24);
    }
  }
  &-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ul {
      @include reset-list;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include desktopXL {
        margin: 0 uniWXL(-10);
      }
      @include desktop {
        margin: 0 uniW(-10);
      }
      li {
        @include desktopXL {
          font-size: uniWXL(14);
          margin: 0 uniWXL(10);
        }
        @include desktop {
          font-size: uniW(14);
          margin: 0 uniW(10);
        }
        button {
          position: relative;
          &::after {
            position: absolute;
            content: '';
            width: 100%;
            border-bottom: 1px solid $main-grey;
            left: 0;
            bottom: -13px;
            opacity: 0;
            transition: opacity 0.3s;
          }
          &.active {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &-burger-btn {
    img {
      width: uniWM(24);
    }
  }
}
</style>
