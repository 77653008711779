<template>
  <section class="booking" id="booking">
    <div class="container">
      <div class="booking-wrapper">
        <img v-if="device !== 'mobile'" src="@/assets/img/booking.png" />
        <img v-else src="@/assets/img/booking-mobile.png" />
        <div class="booking-text-block">
          <p>
            Мы всегда готовы поселить вас в одном<br
              v-if="device !== 'mobile'"
            />
            из наших светлых уютных номеров.<br /><br />
            Для брони просто нажмите на кнопку:
          </p>
          <a
            href="https://reservationsteps.ru/rooms/index/8fd0fe63-c684-4f99-a522-fc6030425767"
            target="_blank"
            class="btn btn-orange"
            >забронировать</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionBooking',
}
</script>

<style lang="scss" scoped>
.booking {
  @include desktopXL {
    margin-bottom: uniWXL(93);
  }
  @include desktop {
    margin-bottom: uniW(93);
  }
  @include mobile {
    margin-bottom: uniWM(30);
  }
  &-wrapper {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      margin: 0 uniWM(-20);
    }
    img {
      @include desktopXL {
        width: uniWXL(738);
      }
      @include desktop {
        width: uniW(738);
      }
      @include mobile {
        width: 100%;
        margin: 0 uniWM(-20);
      }
    }
  }
  &-text-block {
    background: $main-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include desktopXL {
      width: uniWXL(630);
      height: uniWXL(404);
      margin-left: uniWXL(-88);
    }
    @include desktop {
      width: uniW(630);
      height: uniW(404);
      margin-left: uniW(-88);
    }
    @include mobile {
      width: calc(100% - uniWM(40));
      height: auto;
      padding: uniWM(40) uniWM(20);
      margin-top: uniWM(-139);
    }
    p {
      color: $orange;
      text-align: center;
      @include desktopXL {
        line-height: uniWXL(22);
        margin-bottom: uniWXL(32);
      }
      @include desktop {
        line-height: uniW(22);
        margin-bottom: uniW(32);
      }
      @include mobile {
        line-height: uniWM(16);
        margin-bottom: uniWM(40);
      }
    }
  }
}
</style>
