<template>
  <section class="apartments" id="apartments">
    <div class="container">
      <h1 class="apartments-title">Наши <span>апартаменты:</span></h1>
      <div v-if="device !== 'mobile'">
        <div class="apartment">
          <ApartmentView :room="'room-8'" :count="13">
            <template #info>
              <div class="apartment-info">
                <h6>Апартаменты 8&nbsp;&nbsp;|&nbsp;&nbsp;2 этаж</h6>
                <p>
                  С большого балкона открывается вид на горы. Спальные места:
                  двухспальная кровать и раскладной диван. Кровать расположена в
                  отдельной нише. Возможно размещение до 4 человек.
                </p>
                <p>
                  Для детей может быть установлена детская кровать и стул для
                  кормления.
                </p>
                <p>
                  Кухня и ванна оснащены всем необходимым для приготовления еды:
                  посуда, столовые приборы, микроволновка, плита, чайник,
                  духовка, холодильник, кондиционер, стиральная машина.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-12'" :count="17">
            <template #info>
              <div class="apartment-info">
                <h6>Апартаменты 12&nbsp;&nbsp;|&nbsp;&nbsp;3 этаж</h6>
                <p>
                  С большого балкона открывается вид на горы. Спальные места:
                  двухспальная кровать и раскладной диван.
                </p>
                <p>
                  Кровать расположена в отдельной комнате-нише. Спальные места:
                  двухспальная кровать и раскладной диван. Возможно размещение
                  до 4 человек.
                </p>
                <p>
                  Для детей может быть установлена детская кровать и стул для
                  кормления.
                </p>
                <p>
                  Кухня и ванна оснащены всем необходимым для приготовления еды:
                  посуда, столовые приборы, микроволновка, плита, чайник,
                  холодильник, кондиционер, стиральная машина.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-18'" :count="13">
            <template #info>
              <div class="apartment-info">
                <h6>Апартаменты 18&nbsp;&nbsp;|&nbsp;&nbsp;4 этаж</h6>
                <p>
                  Панорамные окна в пол, с большого балкона открывается вид на
                  горы. Спальные места: двухспальная кровать и раскладной диван.
                  Возможно размещение до 4 человек.
                </p>
                <p>
                  Для детей может быть установлена детская кровать и стул для
                  кормления.
                </p>
                <p>
                  Кухня и ванна оснащены всем необходимым для приготовления еды:
                  посуда, столовые приборы, микроволновка, плита, чайник,
                  холодильник, кондиционер, стиральная машина.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
        <div class="apartment">
          <ApartmentView :room="'room-19'" :count="13">
            <template #info>
              <div class="apartment-info">
                <h6>Апартаменты 19&nbsp;&nbsp;|&nbsp;&nbsp;4 этаж</h6>
                <p>
                  Панорамные окна в пол, с большого балкона открывается вид на
                  горы. Спальные места: двухспальная кровать и раскладной диван.
                  Возможно размещение до 4 человек.
                </p>
                <p>
                  Для детей может быть установлена детская кровать и стул для
                  кормления.
                </p>
                <p>
                  Кухня и ванна оснащены всем необходимым для приготовления еды:
                  посуда, столовые приборы, микроволновка, плита, чайник,
                  холодильник, кондиционер, стиральная машина.
                </p>
              </div>
            </template>
          </ApartmentView>
        </div>
      </div>
      <div v-else>
        <div class="apartment">
            <ApartmentView :room="'room-8'" :count="13">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 8&nbsp;&nbsp;|&nbsp;&nbsp;2 этаж</h6>
                  <p>
                    С большого балкона открывается вид на горы. Спальные места:
                    двухспальная кровать и раскладной диван. Кровать расположена
                    в отдельной нише. Возможно размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    духовка, холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-12'" :count="17">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 12&nbsp;&nbsp;|&nbsp;&nbsp;3 этаж</h6>
                  <p>
                    С большого балкона открывается вид на горы. Спальные места:
                    двухспальная кровать и раскладной диван.
                  </p>
                  <p>
                    Кровать расположена в отдельной комнате-нише. Спальные
                    места: двухспальная кровать и раскладной диван. Возможно
                    размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-18'" :count="13">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 18&nbsp;&nbsp;|&nbsp;&nbsp;4 этаж</h6>
                  <p>
                    Панорамные окна в пол, с большого балкона открывается вид на
                    горы. Спальные места: двухспальная кровать и раскладной
                    диван. Возможно размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
          <div class="apartment">
            <ApartmentView :room="'room-19'" :count="13">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 19&nbsp;&nbsp;|&nbsp;&nbsp;4 этаж</h6>
                  <p>
                    Панорамные окна в пол, с большого балкона открывается вид на
                    горы. Спальные места: двухспальная кровать и раскладной
                    диван. Возможно размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
      </div>
      <!-- <Swiper
        v-else
        :slides-offset-after="20"
        :slides-offset-before="20"
        :space-between="20"
        :slides-per-view="'auto'"
        :modules="modules"
        :pagination="{
          el: '.pagination-apartments',
          clickable: true,
        }"
        @swiper="onSwiper"
        class="swiper-apartments"
      >
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-8'" :count="13">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 8&nbsp;&nbsp;|&nbsp;&nbsp;2 этаж</h6>
                  <p>
                    С большого балкона открывается вид на горы. Спальные места:
                    двухспальная кровать и раскладной диван. Кровать расположена
                    в отдельной нише. Возможно размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    духовка, холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-12'" :count="17">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 12&nbsp;&nbsp;|&nbsp;&nbsp;3 этаж</h6>
                  <p>
                    С большого балкона открывается вид на горы. Спальные места:
                    двухспальная кровать и раскладной диван.
                  </p>
                  <p>
                    Кровать расположена в отдельной комнате-нише. Спальные
                    места: двухспальная кровать и раскладной диван. Возможно
                    размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-18'" :count="13">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 18&nbsp;&nbsp;|&nbsp;&nbsp;4 этаж</h6>
                  <p>
                    Панорамные окна в пол, с большого балкона открывается вид на
                    горы. Спальные места: двухспальная кровать и раскладной
                    диван. Возможно размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="apartment">
            <ApartmentView :room="'room-19'" :count="13">
              <template #info>
                <div class="apartment-info">
                  <h6>Апартаменты 19&nbsp;&nbsp;|&nbsp;&nbsp;4 этаж</h6>
                  <p>
                    Панорамные окна в пол, с большого балкона открывается вид на
                    горы. Спальные места: двухспальная кровать и раскладной
                    диван. Возможно размещение до 4 человек.
                  </p>
                  <p>
                    Для детей может быть установлена детская кровать и стул для
                    кормления.
                  </p>
                  <p>
                    Кухня и ванна оснащены всем необходимым для приготовления
                    еды: посуда, столовые приборы, микроволновка, плита, чайник,
                    холодильник, кондиционер, стиральная машина.
                  </p>
                </div>
              </template>
            </ApartmentView>
          </div>
        </SwiperSlide>
      </Swiper> 
      <div class="pagination-apartments orange" />-->
    </div>
    <FullView
      v-if="fullImages.isShow"
      :initialSlide="fullImages.initialSlide"
      :count="fullImages.count"
      :room="fullImages.room"
      @close="setFullImagesParams({ isShow: false })"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from 'vue'
// import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import { Pagination } from 'swiper'

import ApartmentView from './ApartmentView.vue'
import FullView from './FullView.vue'
export default {
  name: 'SectionApartments',
  components: {
    ApartmentView,
    // Swiper,
    // SwiperSlide,
    FullView,
  },
  computed: {
    ...mapState(['fullImages']),
  },
  methods: {
    ...mapMutations(['setFullImagesParams']),
  },
  setup() {
    const swiper = ref(null)

    const onSwiper = (sw) => {
      swiper.value = sw
    }

    return {
      onSwiper,
      modules: [Pagination],
    }
  },
}
</script>

<style lang="scss" scoped>
.apartment {
  @include mobile {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.apartments {
  background: $main-grey;
  @include desktopXL {
    padding: uniWXL(60) 0;
  }
  @include desktop {
    padding: uniW(60) 0;
  }
  @include mobile {
    padding: uniWM(40) 0 uniWM(65) 0;
  }
  &-title {
    color: $white;
    @include desktopXL {
      font-size: uniWXL(70);
      line-height: uniWXL(93);
      margin-bottom: uniWXL(60);
    }
    @include desktop {
      font-size: uniW(70);
      line-height: uniW(93);
      margin-bottom: uniW(60);
    }
    @include mobile {
      font-size: uniWM(40);
      line-height: uniWM(40);
      margin-bottom: uniWM(40);
    }
    span {
      color: $orange;
      font-style: italic;
      @include mobile {
        transform: translateX(uniWM(24));
        display: block;
        width: fit-content;
      }
    }
  }
  .apartment {
    @include desktopXL {
      margin-bottom: uniWXL(100);
    }
    @include desktop {
      margin-bottom: uniW(100);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &-info {
      @include desktopXL {
        max-width: uniWXL(522);
      }
      @include desktop {
        max-width: uniW(522);
      }
      @include mobile {
        background: white;
        width: uniWM(305);
        // height: uniWM(579);
        height: auto;
        padding: uniWM(20);
        margin-top: -1px;
      }
      h6 {
        color: $orange;
        @include desktopXL {
          font-size: uniWXL(16);
          line-height: uniWXL(22);
          margin-bottom: uniWXL(40);
        }
        @include desktop {
          font-size: uniW(16);
          line-height: uniW(22);
          margin-bottom: uniW(40);
        }
        @include mobile {
          font-size: uniWM(16);
          line-height: uniWM(22);
          margin-bottom: uniWM(15);
        }
      }
      p {
        color: $white;
        @include desktopXL {
          font-size: uniWXL(16);
          line-height: uniWXL(22);
          margin-bottom: uniWXL(20);
        }
        @include desktop {
          font-size: uniW(16);
          line-height: uniW(22);
          margin-bottom: uniW(20);
        }
        @include mobile {
          color: $main-grey;
          font-size: uniWM(16);
          line-height: uniWM(22);
          margin-bottom: uniWM(20);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.swiper-apartments {
  @include mobile {
    width: calc(100% + uniWM(40));
    margin: 0 uniWM(-20);
  }
  .swiper-slide {
    width: fit-content;
  }
}
.pagination-apartments {
  left: 50%;
  transform: translateX(-50%);
  bottom: uniWM(-35);
}
</style>
